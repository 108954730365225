import React, {useState, useEffect} from 'react'

function Sample() {
  const [counter, setCounter] = useState(0)
  const [input, setInput] = useState("")

  useEffect(() => {
    console.log("an effect has been run")
    // this is currently being called on every state change
    // add a second argument to useEffect to only call it on specific changes
    // the second argument would be a bracket list of const that are targeted for the useEffect call
  })

  return (
    <div>
      <h2>Same Component</h2>
      <div>
        <button onClick={()=> setCounter(counter + 1)}>add count</button>
        <h1>{counter}</h1>
      </div>
    </div>
  )
}

export default Sample
