import React from 'react'
import Slider from './Slider'

function Selector(props) {
  const item = props.item

  return (
    <div className="row">
      <div className="col-md-4">
        <h2>{item}</h2>
        <p>a little about this</p>
      </div>
      <div className="col-md-8">
        <div>
          Importance selector
        </div>
        <Slider name={item} value={3} onChange={()=>{console.log('the Slider changed')}} />
      </div>
    </div>
  )
}

export default Selector
