import React, {useState, useEffect} from 'react'

function Slider(props) {
  const name = props.name
  const [value, setValue] = useState(props.value)
  const inputId = "slider-"+name

  useEffect(() => {
    console.log(name+" value has changed. "+name+" value is now "+value)
  }, [value])


  return (
    <div className="slidecontainer">
      <input className="slider selector-slider" id={inputId} type="range" min="1" max="5" value={value} onChange={(e)=>{setValue(e.target.value)}} ></input>
      <div className="selector-description-box">
        <p>osme other stuff about the selection</p>
      </div>
    </div>
  )
}

export default Slider
