import React, {useState, useEffect} from 'react'
import Selector from './Selector'

function Selectors() {
  const list = ["list1", "item2", "thing3", "box4", "other stuff"]

  return list.map((i, item)=>(
    <li key={item}>
      <Selector item={i} />
    </li>
  ))
}

export default Selectors
