import './App.scss';
import {Container} from 'react-bootstrap'
import Selectors from './components/Selectors';
import Sample from './components/Sample';
import BootstrapGridSample from './components/BootstrapGridSample';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Container className="header">
          header stuff
        </Container>
      </header>
      <Container>
        <Sample />
      </Container>
      <Container className="selector-box">
        <Selectors />
      </Container>
      <BootstrapGridSample />
    </div>
  );
}

export default App;
