import React from 'react'

// https://getbootstrap.com/docs/5.0/layout/grid/

function BootstrapGridSample() {
  return (
    <div className="bootstrap-grid-sample">

      <div class="container">
        <div class="row">
          <div class="col">
            Column
          </div>
          <div class="col">
            Column
          </div>
          <div class="col">
            Column
          </div>
        </div>
      </div>

      <br /><br /><br />

      <div class="container">
        <div class="row">
          <div class="col">
            1 of 2
          </div>
          <div class="col">
            2 of 2
          </div>
        </div>
        <div class="row">
          <div class="col">
            1 of 3
          </div>
          <div class="col">
            2 of 3
          </div>
          <div class="col">
            3 of 3
          </div>
        </div>
      </div>
      
      <br /><br /><br />
      
      <div class="container">
        <div class="row">
          <div class="col">
            1 of 3
          </div>
          <div class="col-6">
            2 of 3 (wider)
          </div>
          <div class="col">
            3 of 3
          </div>
        </div>
        <div class="row">
          <div class="col">
            1 of 3
          </div>
          <div class="col-5">
            2 of 3 (wider)
          </div>
          <div class="col">
            3 of 3
          </div>
        </div>
      </div>
      
      <br /><br /><br />
      
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col col-lg-2">
            1 of 3
          </div>
          <div class="col-md-auto">
            Variable width content
          </div>
          <div class="col col-lg-2">
            3 of 3
          </div>
        </div>
        <div class="row">
          <div class="col">
            1 of 3
          </div>
          <div class="col-md-auto">
            Variable width content
          </div>
          <div class="col col-lg-2">
            3 of 3
          </div>
        </div>
      </div>

      <br /><br /><br />

      <div class="container">
        <div class="row">
          <div class="col">col</div>
          <div class="col">col</div>
          <div class="col">col</div>
          <div class="col">col</div>
        </div>
        <div class="row">
          <div class="col-8">col-8</div>
          <div class="col-4">col-4</div>
        </div>
      </div>
      
      <br /><br /><br />
      
      <div class="container">
        <div class="row">
          <div class="col-sm-8">col-sm-8</div>
          <div class="col-sm-4">col-sm-4</div>
        </div>
        <div class="row">
          <div class="col-sm">col-sm</div>
          <div class="col-sm">col-sm</div>
          <div class="col-sm">col-sm</div>
        </div>
      </div>

      <br /><br /><br />
      
      <div class="container">
        {/* <!-- Stack the columns on mobile by making one full-width and the other half-width --> */}
        <div class="row">
          <div class="col-md-8">.col-md-8</div>
          <div class="col-6 col-md-4">.col-6 .col-md-4</div>
        </div>

        {/* <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop --> */}
        <div class="row">
          <div class="col-6 col-md-4">.col-6 .col-md-4</div>
          <div class="col-6 col-md-4">.col-6 .col-md-4</div>
          <div class="col-6 col-md-4">.col-6 .col-md-4</div>
        </div>

        {/* <!-- Columns are always 50% wide, on mobile and desktop --> */}
        <div class="row">
          <div class="col-6">.col-6</div>
          <div class="col-6">.col-6</div>
        </div>
      </div>

      <br /><br /><br />
      
      <div class="container">
        <div class="row row-cols-2">
          <div class="col">Column</div>
          <div class="col">Column</div>
          <div class="col">Column</div>
          <div class="col">Column</div>
        </div>
      </div>

      <br /><br /><br />
      
      <div class="container">
        <div class="row row-cols-3">
          <div class="col">Column</div>
          <div class="col">Column</div>
          <div class="col">Column</div>
          <div class="col">Column</div>
        </div>
      </div>

      <br /><br /><br />
      
      <div class="container">
        <div class="row row-cols-auto">
          <div class="col">Column</div>
          <div class="col">Column</div>
          <div class="col">Column</div>
          <div class="col">Column</div>
        </div>
      </div>

      <br /><br /><br />
      
      <div class="container">
        <div class="row row-cols-4">
          <div class="col">Column</div>
          <div class="col">Column</div>
          <div class="col-6">Column</div>
          <div class="col">Column</div>
        </div>
      </div>

      <br /><br /><br />
      
      <div class="container">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
          <div class="col">Column</div>
          <div class="col">Column</div>
          <div class="col">Column</div>
          <div class="col">Column</div>
        </div>
      </div>

      <br /><br /><br />
      
      <div class="container">
        <div class="row">
          <div class="col-sm-3">
            Level 1: .col-sm-3
          </div>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-8 col-sm-6">
                Level 2: .col-8 .col-sm-6
              </div>
              <div class="col-4 col-sm-6">
                Level 2: .col-4 .col-sm-6
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default BootstrapGridSample
